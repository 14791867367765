/* eslint-disable max-len */

export const endpoints = {
  shopifyPimService:
    'https://ty3zskh7ql.execute-api.us-east-1.amazonaws.com/prod/v1/',
  ordersService:
    'https://kzm3wgasoa.execute-api.us-east-1.amazonaws.com/prod/v1/shopify-orders/',
  reportsService:
    'https://s695k10oql.execute-api.us-east-1.amazonaws.com/prod/v1/report-generator/',
  boxesService:
    'https://6xyl1vohua.execute-api.us-east-1.amazonaws.com/prod/v1/box-packer/',
  wavingService:
    'https://0l1evz9r25.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-waving',
  productsService:
    'https://t7otin5bf7.execute-api.us-east-1.amazonaws.com/prod/v1/shopify-products/get-products',
  wmsService:
    'https://cud7e5o1la.execute-api.us-east-1.amazonaws.com/prod/v1/excelsior-wms/',
  inventorySyncService:
    'https://20gid01lmi.execute-api.us-east-1.amazonaws.com/prod/v1/inventory-sync/',
  dcAutomationsGatewayHttpService:
    'https://2dlds1zpw0.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-http',
  laborManagementService:
    'https://o1gbqvg8ii.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-lm',
  iamService:
    'https://q1g1r5g6wj.execute-api.us-east-1.amazonaws.com/prod/v1/excelsior-iam/',
  productRecommendationsService:
    'https://n5fvmcizoc.execute-api.us-east-1.amazonaws.com/prod/v1/product-recommendations',
  customerServiceService:
    'https://jieydkk8kc.execute-api.us-east-1.amazonaws.com/prod/v1/excelsior-customer-service',
  provisioningManagerService:
    'https://33t5yrey07.execute-api.us-east-1.amazonaws.com/prod/v1/',
  dataWarehouseService:
    'https://19u7taf3fi.execute-api.us-east-1.amazonaws.com/prod/v3/data-warehouse/',
  batchPrintingService:
    'https://pgzzccxrwk.execute-api.us-east-1.amazonaws.com/prod/v1/excelsior-batch-printing/',
  fbCommentsService:
    'https://p0woe9wz00.execute-api.us-east-1.amazonaws.com/prod/',
  cloudant:
    'https://b81edb5f-fc75-4511-a9e0-552655dc6979-bluemix:57942749954a2268db565cc4c1236daaae04b85ab01b4fecbf4a28c715366c6b@b81edb5f-fc75-4511-a9e0-552655dc6979-bluemix.cloudant.com',
  cloudantDBStatus: 'aws-dynamo-provisioning',
  cloudantExcelsiorBatches:
    'https://48c5dd24-e86d-4063-ad95-384c10ede92f-bluemix:1df7930af1e33cfd035359f0f00ec11865ab983c516aca278e8dd1898bef6880@48c5dd24-e86d-4063-ad95-384c10ede92f-bluemix.cloudant.com',
  cloudantDBExcelsiorBatches: 'excelsior-batch-printing',
  shipmentReviewWebSocket:
  'wss://aydhbfrnhi.execute-api.us-east-1.amazonaws.com/prod',
  shipmentReviewService:
    'https://7iabuvkpk8.execute-api.us-east-1.amazonaws.com/prod/v1/shipment-reviews',
  endpoints: {
    adAccounts:
      'https://6omx10hmbg.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-ad-perf/ad-accounts',
    adAccountInsights: {
      all: 'https://hgdv5uyxk0.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-marketing/proxy-business-ad-account-insights'
    },
    adManager: {
      all: 'https://hgdv5uyxk0.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-marketing/observations'
    },
    adCopy:{
      copyAds:'https://9jqh25lwqh.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-copy/ad-jobs',
      copyAdsets:'https://9jqh25lwqh.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-copy/adset',
      copyCampaigns:'https://9jqh25lwqh.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-copy/campaign',
      all:'https://9jqh25lwqh.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-copy/ad-jobs',
      delete:'https://9jqh25lwqh.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-copy/ad-jobs'
    },
    adManagerHistory: {
      all: 'https://hgdv5uyxk0.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-marketing/multiday-observations'
    },
    adRule: {
      all: 'https://6omx10hmbg.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-ad-perf/rules',
      update:
        'https://6omx10hmbg.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-ad-perf/rules',
      batch:
        'https://6omx10hmbg.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-ad-perf/rules/batch',
      delete:
        'https://6omx10hmbg.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-ad-perf/rules',
      store:
        'https://6omx10hmbg.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-ad-perf/rules',
      autocomplete:
        'https://6omx10hmbg.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-ad-perf/rules/entity-id-completion',
      runOnce:
        'https://6omx10hmbg.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-ad-perf/rules/run-once',
      dashboardMetrics:
        'https://6omx10hmbg.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-ad-perf/dashboard-metrics'
    },
    adRuleLogs: {
      all: 'https://6omx10hmbg.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-ad-perf/rules/marketing-events'
    },
    adRuleTriggerObservations: {
      get: 'https://6omx10hmbg.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-ad-perf/trigger-observations'
    },
    adRuleEvaluations: {
      get: 'https://6omx10hmbg.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-ad-perf/rules/evaluate'
    },
    adRuleSettings: {
      get: 'https://6omx10hmbg.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-ad-perf/rules/settings',
      update:
        'https://6omx10hmbg.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-ad-perf/rules/settings'
    },
    adRuleJob: {
      all: 'https://6omx10hmbg.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-ad-perf/rules/jobs'
    },
    adRuleAudit: {
      all: 'https://6omx10hmbg.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-ad-perf/rules/events'
    },
    adLimitSummary: {
      all: 'https://hgdv5uyxk0.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-marketing/page-ad-volume-summary'
    },
    adLimitHistory: {
      all: 'https://hgdv5uyxk0.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-marketing/page-ad-volume-history'
    },
    adLimitPage: {
      all: 'https://hgdv5uyxk0.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-marketing/ads-lifetime'
    },
    creativeReport: {
      all: 'https://hgdv5uyxk0.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-marketing/current-creatives-report',
      getHistorical: 'https://hgdv5uyxk0.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-marketing/historical-creatives-report',
      typeahead: 'https://hgdv5uyxk0.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-marketing/creative-name-completion'
    },
    shipmentReviewSettings: {
      allMidZones:
        'https://7iabuvkpk8.execute-api.us-east-1.amazonaws.com/prod/v1/shipment-reviews/mid-zone-management',
      updateMidZones:
        'https://7iabuvkpk8.execute-api.us-east-1.amazonaws.com/prod/v1/shipment-reviews/mid-zone-management'
    },
    giftCardRedemptions: {
      getGiftCardRedemptions: 'https://mucgfl8naa.execute-api.us-east-1.amazonaws.com/v1/giftcard-redemption-cache/redemptions'
    },
    fraudEmail: {
      all: 'https://olhbxy67j3.execute-api.us-east-1.amazonaws.com/prod/api/v1/emails',
      store:
        'https://olhbxy67j3.execute-api.us-east-1.amazonaws.com/prod/api/v1/emails',
      delete:
        'https://olhbxy67j3.execute-api.us-east-1.amazonaws.com/prod/api/v1/emails'
    },
    fraudAddress: {
      all: 'https://olhbxy67j3.execute-api.us-east-1.amazonaws.com/prod/api/v1/addresses',
      formatAddress: 'https://olhbxy67j3.execute-api.us-east-1.amazonaws.com/prod/api/v1/format-address',
      store:
        'https://olhbxy67j3.execute-api.us-east-1.amazonaws.com/prod/api/v1/addresses',
      delete:
        'https://olhbxy67j3.execute-api.us-east-1.amazonaws.com/prod/api/v1/addresses'
    },
    facebookAttribution: {
      all: 'https://nw9adwuund.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-attribution/attributions',
      getByOrderId:
        'https://nw9adwuund.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-attribution/order-attribution',
      getValidityReport:
        'https://nw9adwuund.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-attribution/ad-id-validity-report',
      getRates:
        'https://nw9adwuund.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-attribution/summary/attribution-rate',
      getReasons:
        'https://nw9adwuund.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-attribution/summary/reasons',
      getClicks:
        'https://nw9adwuund.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-attribution/clicks'
    },
    recommendation: {
      all: 'https://hgdv5uyxk0.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-marketing/recommendations'
    },
    facebookBidAdjustment: {
      get: 'https://hgdv5uyxk0.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-marketing/bid-adjust',
      update: 'https://hgdv5uyxk0.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-marketing/bid-adjust',
      delete: 'https://hgdv5uyxk0.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-marketing/bid-adjust'
    },
    facebookAdsetLearning: {
      all: 'https://hgdv5uyxk0.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-marketing/adset-learning'
    },
    facebookPagePerformance: {
      all: 'https://hgdv5uyxk0.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-marketing/page-performance',
      queryPagePerformanceReport: 'https://hgdv5uyxk0.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-marketing/historical-page-performance-report'
    },
    businessReport: {
      discountCodes:
        'https://wxlj86beud.execute-api.us-east-1.amazonaws.com/prod/v1/business-reports/discount-codes',
      discountCodesFrequency:
        'https://wxlj86beud.execute-api.us-east-1.amazonaws.com/prod/v1/business-reports/discount-code-frequency/discount-codes-usage-per-day',
      inventorySnapshot:
        'https://wxlj86beud.execute-api.us-east-1.amazonaws.com/prod/v1/business-reports/inventory-snapshot'

    },
    fulfillmentMetrics: {
      webSocket: 'wss://2nlay61u9g.execute-api.us-east-1.amazonaws.com/prod',
      getDetails:
        'https://cud7e5o1la.execute-api.us-east-1.amazonaws.com/prod/v1/excelsior-wms/query-projected-metrics-updates',
      getMetrics:
        'https://cud7e5o1la.execute-api.us-east-1.amazonaws.com/prod/v1/excelsior-wms/query-metrics',
      updateProjectedNumbers:
        'https://cud7e5o1la.execute-api.us-east-1.amazonaws.com/prod/v1/excelsior-wms/update-projected-metrics'
    },
    audienceLookalike: {
      store:
        'https://8jm5rnko8h.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-audience/lookalike',
      all:
        'https://8jm5rnko8h.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-audience/lookalike',
      share:
        'https://8jm5rnko8h.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-audience/share-lookalike'
    },
    audienceTemplate: {
      all: 'https://8jm5rnko8h.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-audience/templates',
      get: 'https://8jm5rnko8h.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-audience/templates',
      store:
      'https://8jm5rnko8h.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-audience/templates',
      update:
      'https://8jm5rnko8h.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-audience/templates',
      runOnce:
      'https://8jm5rnko8h.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-audience/templates/run-once',
      delete:
      'https://8jm5rnko8h.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-audience/templates',
      jobsStatus: 'https://8jm5rnko8h.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-audience/templates-job-status',
      validateVideoIds: 'https://8jm5rnko8h.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-audience/video-id-validation'
    },
    facebookQuery: {
      all: 'https://p3zqdvkukc.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-query/queries',
      get: 'https://p3zqdvkukc.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-query/queries',
      store:
        'https://p3zqdvkukc.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-query/queries',
      update:
        'https://p3zqdvkukc.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-query/queries',
      delete:
        'https://p3zqdvkukc.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-query/queries',
      cancel:
        'https://p3zqdvkukc.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-query/query-cancel',
      runOnce:
        'https://p3zqdvkukc.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-query/query-run-once',
      jobs:
        'https://p3zqdvkukc.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-query/queries/jobs',
      export: 'https://p3zqdvkukc.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-query/results-csv'
    },
    replenishmentReport: {
      all: 'https://19u7taf3fi.execute-api.us-east-1.amazonaws.com/prod/v1/data-warehouse/excelsior/report-replenishment-skus',
      orderAging: 'https://19u7taf3fi.execute-api.us-east-1.amazonaws.com/prod/v1/data-warehouse/report-order-status-progressions'
    },
    facebook:{
      getPages:'https://8jm5rnko8h.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-audience/pages/name-completion'
    },
    dataWarehouseService: {
      fulfillments: 'https://19u7taf3fi.execute-api.us-east-1.amazonaws.com/prod/v2/data-warehouse/excelsior/fulfillments'
    },
    skuInventory:{
      all: 'https://cud7e5o1la.execute-api.us-east-1.amazonaws.com/prod/v2/excelsior-wms/sku-inventory',
      updateStatus: 'https://cud7e5o1la.execute-api.us-east-1.amazonaws.com/prod/v1/excelsior-wms/skus/status',
      sync: 'https://dmv2sudy9c.execute-api.us-east-1.amazonaws.com/prod/v1/inventory/sync-sku'
    },
    manageItemMaster: {
      queryItemMasterDetails: 'https://6mwz0tk0v1.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-item-master/query-item-master-details',
      insertItemMasterDetails: 'https://6mwz0tk0v1.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-item-master/insert-item-master-details',
      updateItemMasterDetails: 'https://6mwz0tk0v1.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-item-master/update-item-master-details'
    },
    automatedReports: {
      getReportsConfigurations: 'https://wxlj86beud.execute-api.us-east-1.amazonaws.com/prod/v1/business-reports/report-configurations',
      addReportConfiguration: 'https://wxlj86beud.execute-api.us-east-1.amazonaws.com/prod/v1/business-reports/report-configurations',
      updateReportConfiguration: 'https://wxlj86beud.execute-api.us-east-1.amazonaws.com/prod/v1/business-reports/report-configurations/{report_id}',
      getReportRecipients: 'https://wxlj86beud.execute-api.us-east-1.amazonaws.com/prod/v1/business-reports/report-configurations/{report_id}/recipients',
      removeReportRecipient: 'https://wxlj86beud.execute-api.us-east-1.amazonaws.com/prod/v1/business-reports/report-configurations/{report_id}/recipients/{recipient_id}',
      updateReportRecipient: 'https://wxlj86beud.execute-api.us-east-1.amazonaws.com/prod/v1/business-reports/report-configurations/{report_id}/recipients/{recipient_id}',
      addReportRecipients: 'https://wxlj86beud.execute-api.us-east-1.amazonaws.com/prod/v1/business-reports/report-configurations/{report_id}/recipients'
    },
    carrierInvoice: {
      presignedUploadUrl: 'https://z4dywog1ye.execute-api.us-east-1.amazonaws.com/prod/v1/accounting-snowflake/presigned-upload-url',
      services: 'https://z4dywog1ye.execute-api.us-east-1.amazonaws.com/prod/v1/accounting-snowflake/list-carrier-services'
    },
    marketingCreative:{
      all: 'https://b9xv2ut9gj.execute-api.us-east-1.amazonaws.com/prod/v1/marketing-creative/creatives',
      get: 'https://b9xv2ut9gj.execute-api.us-east-1.amazonaws.com/prod/v1/marketing-creative/creatives',
      store: 'https://b9xv2ut9gj.execute-api.us-east-1.amazonaws.com/prod/v1/marketing-creative/creatives',
      update: 'https://b9xv2ut9gj.execute-api.us-east-1.amazonaws.com/prod/v1/marketing-creative/creatives',
      delete: 'https://b9xv2ut9gj.execute-api.us-east-1.amazonaws.com/prod/v1/marketing-creative/creatives',
      createAd: 'https://b9xv2ut9gj.execute-api.us-east-1.amazonaws.com/prod/v1/marketing-creative/create-ad',
      allChoices: 'https://b9xv2ut9gj.execute-api.us-east-1.amazonaws.com/prod/v1/marketing-creative/creative-fields'
    },
    marketDashboard: 'https://kosq0cymb1.execute-api.us-east-1.amazonaws.com/prod/v1/market-dash/query',
    discountTier:{
      all: 'https://dv31npt5t9.execute-api.us-east-1.amazonaws.com/prod/v1/discount-tiers/campaign',
      store: 'https://dv31npt5t9.execute-api.us-east-1.amazonaws.com/prod/v1/discount-tiers/campaign',
      update: 'https://dv31npt5t9.execute-api.us-east-1.amazonaws.com/prod/v1/discount-tiers/campaign',
      delete: 'https://dv31npt5t9.execute-api.us-east-1.amazonaws.com/prod/v1/discount-tiers/campaign',
      get: 'https://dv31npt5t9.execute-api.us-east-1.amazonaws.com/prod/v1/discount-tiers/campaign',
      getProducts: 'https://dv31npt5t9.execute-api.us-east-1.amazonaws.com/prod/v1/discount-tiers/product',
      switchCampaignStatus: 'https://dv31npt5t9.execute-api.us-east-1.amazonaws.com/prod/v1/discount-tiers/switch-campaign-status',
      downloadCodes: 'https://dv31npt5t9.execute-api.us-east-1.amazonaws.com/prod/v1/discount-tiers/download-codes'
    },
    shipmentWave:{
      preview: 'https://xp9rdysg5d.execute-api.us-east-1.amazonaws.com/prod/v1/ship-wave/query-shipments',
      assign: 'https://xp9rdysg5d.execute-api.us-east-1.amazonaws.com/prod/v1/ship-wave/query-shipments',
      update: 'https://xp9rdysg5d.execute-api.us-east-1.amazonaws.com/prod/v1/ship-wave/wave',
      retry: 'https://xp9rdysg5d.execute-api.us-east-1.amazonaws.com/prod/v1/ship-wave/retry-wave',
      all: 'https://xp9rdysg5d.execute-api.us-east-1.amazonaws.com/prod/v1/ship-wave/wave',
      unassign: 'https://xp9rdysg5d.execute-api.us-east-1.amazonaws.com/prod/v1/ship-wave/unassign-wave'
    },
    cartonLookup: {
      queryCartonHeader: 'https://xqtl7xjpnb.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-carton-lookup/query-carton-header',
      cancelCarton: 'https://xqtl7xjpnb.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-carton-lookup/cancel-insert-carton',
      resetCarton: 'https://xqtl7xjpnb.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-carton-lookup/reset-carton-for-pricing',
      packCarton: 'https://xqtl7xjpnb.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-carton-lookup/carton-force-pack',
      queryTaskDetails: 'https://xqtl7xjpnb.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-carton-lookup/query-task-details',
      queryInventoryTransactions: 'https://xqtl7xjpnb.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-carton-lookup/query-inventory-transactions',
      queryRnRTransactions: 'https://xqtl7xjpnb.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-carton-lookup/query-rnr-transactions',
      queryCartonDetails: 'https://xqtl7xjpnb.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-carton-lookup/query-carton-details',
      queryPickingDetails: 'https://xqtl7xjpnb.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-carton-lookup/query-picking-details',
      queryCartonStatus: 'https://xqtl7xjpnb.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-carton-lookup/query-carton-status',
      queryShippingDivert: 'https://xqtl7xjpnb.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-carton-lookup/query-shipping-divert'
    },
    updateProductTables: {
      getShopifyProduct: 'https://ty3zskh7ql.execute-api.us-east-1.amazonaws.com/prod/v1/products/get-shopify-product',
      updateProductTables: 'https://ty3zskh7ql.execute-api.us-east-1.amazonaws.com/prod/v1/products/update-excelsior-tables'
    },
    peakUnitsBurnDown: {
      getBurnDownUnits: 'https://zzbzghnzl3.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-dashboard-service/get-burn-down-units',
      updateBurnDownUnits: 'https://zzbzghnzl3.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-dashboard-service/update-burn-down-units',
      recalculateBurnDownUnits: 'https://zzbzghnzl3.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-dashboard-service/recalculate-burn-down-units'
    },
    boxConsumption: {
      getBoxConsumptions: 'https://2dlds1zpw0.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-http/box-consumptions',
      refreshBoxConsumptions: 'https://2dlds1zpw0.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-http/report-refresh-request'
    },
    cadenceWavePlan: {
      getWaves: 'https://74pkfx9x97.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-wave-plan-service/waves',
      getCadences: 'https://74pkfx9x97.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-wave-plan-service/cadences',
      createCadences: 'https://74pkfx9x97.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-wave-plan-service/cadences',
      updateCadence: 'https://74pkfx9x97.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-wave-plan-service/cadence',
      updateToteMix: 'https://74pkfx9x97.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-wave-plan-service/update-tote-brand-priority',
      unassignWaves: 'https://74pkfx9x97.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-wave-plan-service/unassign-waves',
      carryOver: 'https://74pkfx9x97.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-wave-plan-service/carry-over',
      getReplenishments: 'https://74pkfx9x97.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-wave-plan-service/replenishments',
      getTotes: 'https://74pkfx9x97.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-wave-plan-service/totes',
      getWorkDistribution: 'https://74pkfx9x97.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-wave-plan-service/work-distribution',
      getWavePlanTV: 'https://74pkfx9x97.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-wave-plan-service/wave-plan-tv',
      getWaveProgression: 'https://74pkfx9x97.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-wave-plan-service/wave-progression',
      printWave: 'https://74pkfx9x97.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-wave-plan-service/print-request',
      loadCartons: 'https://74pkfx9x97.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-wave-plan-service/load-cartons',
      getPrinters: 'https://74pkfx9x97.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-wave-plan-service/printers'
    },
    laborManagement: {
      getUsers: 'https://o1gbqvg8ii.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-lm/get-lm-user-list',
      getSupervisorList: 'https://o1gbqvg8ii.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-lm/get-lm-supervisor-list',
      getAttendanceUser: 'https://o1gbqvg8ii.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-lm/get-lm-attendance-user',
      updateUserAttendanceRecord: 'https://o1gbqvg8ii.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-lm/update-user-attendance-record',
      createUserAttendanceRecord: 'https://o1gbqvg8ii.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-lm/create-user-attendance-record',
      deleteUserAttendanceRecord: 'https://o1gbqvg8ii.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-lm/delete-user-attendance-record',
      getAttendanceTypes: 'https://o1gbqvg8ii.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-lm/get-lm-attendance-types-list',
      createUser: 'https://o1gbqvg8ii.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-lm/create-user',
      updateUser: 'https://o1gbqvg8ii.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-lm/update-user',
      resetUserPin: 'https://o1gbqvg8ii.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-lm/reset-user-pin',
      createProcedureError: 'https://o1gbqvg8ii.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-lm/create-lm-proc-error',
      updateProcedureError: 'https://o1gbqvg8ii.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-lm/update-lm-proc-error',
      deleteProcedureError: 'https://o1gbqvg8ii.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-lm/delete-procedure-error',
      getProcedureErrorTypes: 'https://o1gbqvg8ii.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-lm/query-lm-procedure-error-types-list',
      getProcedureErrors: 'https://o1gbqvg8ii.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-lm/get-lm-proc-errors',
      safety: {
        querySafetyRecords: 'https://o1gbqvg8ii.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-lm/query-safety-records',
        querySafetyPoints: 'https://o1gbqvg8ii.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-lm/query-safety-points',
        createSafetyRecord: 'https://o1gbqvg8ii.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-lm/create-safety-record',
        updateSafetyRecord: 'https://o1gbqvg8ii.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-lm/update-safety-record',
        deleteSafetyRecord: 'https://o1gbqvg8ii.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-lm/delete-safety-record'
      },
      term: {
        queryTermRecords: 'https://o1gbqvg8ii.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-lm/query-term-records',
        queryTermReasons: 'https://o1gbqvg8ii.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-lm/query-term-reasons',
        createTermRecord: 'https://o1gbqvg8ii.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-lm/create-term-record',
        updateTermRecord: 'https://o1gbqvg8ii.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-lm/update-term-record',
        deleteTermRecord: 'https://o1gbqvg8ii.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-lm/delete-term-record'
      },
      coachings: {
        getCoachings: 'https://o1gbqvg8ii.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-lm/coachings',
        getCoachingTypes: 'https://o1gbqvg8ii.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-lm/coaching-types',
        postCoaching: 'https://o1gbqvg8ii.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-lm/coaching',
        deleteCoaching: 'https://o1gbqvg8ii.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-lm/coaching',
        patchCoaching: 'https://o1gbqvg8ii.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-lm/coaching'
      },
      associate: {
        queryAssociateRecords: 'https://o1gbqvg8ii.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-lm/query-associate-records',
        createAssociateRecord: 'https://o1gbqvg8ii.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-lm/create-associate-record',
        updateAssociateRecord: 'https://o1gbqvg8ii.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-lm/update-associate-record',
        deleteAssociateRecord: 'https://o1gbqvg8ii.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-lm/delete-associate-record'
      }
    },
    manageBarcodes: {
      queryBarcodeAliases: 'https://6mwz0tk0v1.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-item-master/query-barcode-aliases',
      createBarcodeAlias: 'https://6mwz0tk0v1.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-item-master/insert-barcode-aliase',
      updateBarcodeAlias: 'https://6mwz0tk0v1.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-item-master/update-barcode-aliase'
    },
    associateDecouple: {
      queryAssociateDecoupleSummary: 'https://o1gbqvg8ii.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-lm/query-associate-decouple-summary'
    },
    associateUnitDetails: {
      queryAssociateUnitDetails: 'https://2dlds1zpw0.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-http/query-associate-unit-details'
    },
    associateSummary: {
      queryAssociateSummary: 'https://2dlds1zpw0.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-http/query-associate-summary'
    },
    financeReport: {
      queryFinanceReport: 'https://2s3wy63s1l.execute-api.us-east-1.amazonaws.com/prod/v1/finance-report'
    },
    hourlyReport: {
      queryHourlyReport: 'https://2s3wy63s1l.execute-api.us-east-1.amazonaws.com/prod/v1/hourly-report'
    },
    facebookAdScaling: {
      queryTasks: 'https://zk9fo04gfg.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-ad-scaling/tasks',
      queryTasksV2: 'https://zk9fo04gfg.execute-api.us-east-1.amazonaws.com/prod/v2/facebook-ad-scaling/tasks',
      createTask: 'https://zk9fo04gfg.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-ad-scaling/tasks',
      queryDestinationGroups: 'https://zk9fo04gfg.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-ad-scaling/destination-groups',
      createDestinationGroup: 'https://zk9fo04gfg.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-ad-scaling/destination-groups',
      updateDestinationGroup: 'https://zk9fo04gfg.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-ad-scaling/destination-groups/{id}',
      deleteDestinationGroup: 'https://zk9fo04gfg.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-ad-scaling/destination-groups/{id}',
      strategies: {
        queryStrategies: 'https://zk9fo04gfg.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-ad-scaling/strategies',
        queryStrategy: 'https://zk9fo04gfg.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-ad-scaling/strategies/{id}',
        queryStrategyOptions: 'https://zk9fo04gfg.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-ad-scaling/strategy-options',
        updateStrategy: 'https://zk9fo04gfg.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-ad-scaling/strategies/{id}',
        createStrategy: 'https://zk9fo04gfg.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-ad-scaling/strategies',
        deleteStrategy: 'https://zk9fo04gfg.execute-api.us-east-1.amazonaws.com/prod/v1/facebook-ad-scaling/strategies/{id}'
      }
    },
    printPDF: {
      getPrinters: 'https://2dlds1zpw0.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-http/get-dc-printer-print-node-ids',
      printPDF: 'https://2dlds1zpw0.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-http/submit-encoded-pdf-print-node'
    },
    caseIds: {
      getCaseIds: 'https://ayyx9kz46c.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-inventory/get-case-ids'
    },
    createCaseIds: {
      getPrinters: 'https://ayyx9kz46c.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-inventory/get-printers',
      getCustomerIds: 'https://ayyx9kz46c.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-inventory/get-customer-ids',
      createCaseId: 'https://ayyx9kz46c.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-inventory/create-case-ids'
    },
    vendorIds: {
      getVendorIds: 'https://ayyx9kz46c.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-inventory/get-vendor-ids',
      createVendorId: 'https://ayyx9kz46c.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-inventory/create-vendor-id',
      updateVendorId: 'https://ayyx9kz46c.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-inventory/update-vendor-id'
    },
    managePO: {
      getPoHeaders: 'https://ayyx9kz46c.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-inventory/get-po-headers',
      createPoHeader: 'https://ayyx9kz46c.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-inventory/create-po-header',
      updatePoHeader: 'https://ayyx9kz46c.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-inventory/update-po-header'
    },
    managePODetails: {
      getPoDetails: 'https://ayyx9kz46c.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-inventory/get-po-details',
      createPoDetails: 'https://ayyx9kz46c.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-inventory/create-po-details',
      updatePoDetails: 'https://ayyx9kz46c.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-inventory/update-po-details',
      deletePoDetails: 'https://ayyx9kz46c.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-inventory/delete-po-details/{id}'
    },
    carrierIds: {
      getCarrierIds: 'https://ayyx9kz46c.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-inventory/get-carrier-ids',
      createCarrierId: 'https://ayyx9kz46c.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-inventory/create-carrier-id',
      updateCarrierId: 'https://ayyx9kz46c.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-inventory/update-carrier-id'
    },
    reserveLocations: {
      getReserveLocations: 'https://ayyx9kz46c.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-inventory/get-reserve-locations',
      createReserveLocation: 'https://ayyx9kz46c.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-inventory/create-reserve-location',
      updateReserveLocation: 'https://ayyx9kz46c.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-inventory/update-reserve-location'
    },
    pickingLocations: {
      all: 'https://r51yqq3sf5.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-picking/get-picking-locations',
      updatePickingLocation: 'https://r51yqq3sf5.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-picking/update-picking-location',
      getDCProductCodes: 'https://2dlds1zpw0.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-http/get-dc-product-codes'
    },
    pickingOperations: {
      pickedUnitsReports: {
        queryPickedUnitsReports: 'https://r51yqq3sf5.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-picking/picked-units-reports'
      },
      agedTotes: {
        queryAgedTotes: 'https://r51yqq3sf5.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-picking/query-aged-totes'
      },
      pickingShortsSummary: {
        queryPickingShortsSummary: 'https://r51yqq3sf5.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-picking/query-picking-shorts-summary'
      },
      pickingShortsDetail: {
        queryPickingShortsDetail: 'https://r51yqq3sf5.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-picking/query-picking-shorts-detail'
      }
    },
    operationsKeyMetrics: {
      packingShorts: {
        queryPackingShorts: 'https://2dlds1zpw0.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-http/query-packing-shorts'
      },
      associateOutboundHourSummary: {
        queryLMUserIdList: 'https://o1gbqvg8ii.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-lm/query-lm-user-id-list',
        queryTasksTaskingTypes: 'https://2dlds1zpw0.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-http/query-tasks-tasking-types',
        queryAssociateOutboundHourSummary: 'https://2dlds1zpw0.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-http/query-associate-hour-summary'
      },
      associateOutboundHourlyPivot: {
        queryAssociateOutboundHourlyPivot: 'https://2dlds1zpw0.execute-api.us-east-1.amazonaws.com/prod/v2/dc-automations-gateway-http/query-associate-hourly-pivot'
      },
      associateOutboundHourlyPivotRnR: {
        queryAssociateOutboundHourlyPivotRnR: 'https://2dlds1zpw0.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-http/query-associate-hourly-pivot-rnr'
      },
      pickedUnits: {
        queryPickedUnits: 'https://r51yqq3sf5.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-picking/query-picked-units'
      },
      outboundKeyMetricsByTasks: {
        queryOutboundKeyMetrics: 'https://2dlds1zpw0.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-http/query-day-outbound'
      },
      outboundKeyMetrics: {
        getOutboundKeyMetrics: 'https://hiy5lvh6xl.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-key-metrics/get-outbound-key-metrics'
      },
      internalTurnTime: {
        getTurnTimeLoaded: 'https://hiy5lvh6xl.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-key-metrics/get-turn-time-order-loaded',
        getTurnTimeDelivery: 'https://hiy5lvh6xl.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-key-metrics/get-order-delivery-turn-time'
      },
      fulfilledShipments: {
        queryFulfilledShipments: 'https://hiy5lvh6xl.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-key-metrics/query-fulfilled-shipments'
      }
    },
    fileConversions: {
      amazonReview: {
        processAmazonReviewsConversions: 'https://37kilcxra3.execute-api.us-east-1.amazonaws.com/prod/v1/amazon-sync-service/process-amazon-review-conversions'
      }
    },
    queryArchiveWave: 'https://2dlds1zpw0.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-http/query-archive-wave',
    queryTVDailyPivot: 'https://2dlds1zpw0.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-http/query-tv-reporting-associate-hourly-pivot',
    operationsReporting: {
      unwavedSummary: {
        queryUnwavedSummary: 'https://2dlds1zpw0.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-http/query-unwaved-summary'
      },
      orderStatus: {
        queryOrderStatusReport: 'https://2dlds1zpw0.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-http/query-order-status-report',
        queryOrderStatusReportDetails: 'https://2dlds1zpw0.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-http/query-order-status-report-details'
      },
      bridgedStatus: {
        queryBridgedStatusReport: 'https://2dlds1zpw0.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-http/query-bridged-status-report'
      },
      bridgedUnitsSummary: {
        queryBridgedUnitsSummary: 'https://2dlds1zpw0.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-http/query-bridged-units-hour-history'
      },
      unshippedPriority: {
        getUnshippedPriority: 'https://0l1evz9r25.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-waving/get-unshipped-priority'
      },
      taskProgression: {
        queryTaskProgression: 'https://appbhvtoi0.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-wave-progression/query-task-progression'
      },
      taskDetails: {
        queryTaskDetailsAudit: 'https://2dlds1zpw0.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-http/query-task-details-audit'
      },
      dockDoors: {
        queryDockDoors: 'https://2dlds1zpw0.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-http/query-shipping-dock-doors',
        updateDockDoors: 'https://2dlds1zpw0.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-http/update-shipping-dock-doors',
        queryCarrierGroups: 'https://2dlds1zpw0.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-http/query-carrier-groups'
      },
      taskCartonDetail: {
        queryTaskCartonDetail: 'https://2dlds1zpw0.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-http/query-task-carton-detail'
      },
      pickingAllocations: {
        queryPickingAllocations: 'https://r51yqq3sf5.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-picking/query-picking-allocations'
      },
      inventoryInTotes: {
        queryUnitsInTotes: 'https://r51yqq3sf5.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-picking/query-units-in-totes',
        refreshUnitsInTotes: 'https://r51yqq3sf5.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-picking/refresh-units-in-totes'
      }
    },
    operationsTV: {
      tvGoals: {
        queryTvGoals: 'https://2dlds1zpw0.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-http/query-tv-goals',
        updateTvGoals: 'https://2dlds1zpw0.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-http/update-tv-goals'
      }
    },
    operationsSOP: {
      standardOperatingProcedures: {
        queryStandardOperatingProcedures: 'https://2dlds1zpw0.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-http/operations-sop',
        createStandardOperatingProcedure: 'https://2dlds1zpw0.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-http/operations-sop',
        updateStandardOperatingProcedure: 'https://2dlds1zpw0.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-http/operations-sop/{id}',
        deleteStandardOperatingProcedure: 'https://2dlds1zpw0.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-http/operations-sop/{id}'
      }
    },

    discountFunctions: {
      queryDiscounts: 'https://fiq2y4e61f.execute-api.us-east-1.amazonaws.com/prod/v1/discount-functions/discounts',
      queryFunctions: 'https://fiq2y4e61f.execute-api.us-east-1.amazonaws.com/prod/v1/discount-functions/functions',
      createDiscount: 'https://fiq2y4e61f.execute-api.us-east-1.amazonaws.com/prod/v1/discount-functions/discount',
      updateDiscount: 'https://fiq2y4e61f.execute-api.us-east-1.amazonaws.com/prod/v1/discount-functions/discount/{id}'
    },

    wmsDashboard: {
      queryProcessingTimes: 'https://zzbzghnzl3.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-dashboard-service/kpis-page/processing-times',
      queryProcessingTimeGoals: 'https://zzbzghnzl3.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-dashboard-service/kpis-page/processing-time-goals',
      queryProcessingTimesByCarrier: 'https://zzbzghnzl3.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-dashboard-service/kpis-page/processing-times-by-carrier',
      queryProductInventoryCounts: 'https://zzbzghnzl3.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-dashboard-service/dashboard-page/product-inventory-counts',
      queryPickedShortsByDivertSummary: 'https://zzbzghnzl3.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-dashboard-service/dashboard-page/picking-shorts-by-divert-summary',
      queryUnshippedPriorityCartons: 'https://zzbzghnzl3.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-dashboard-service/dashboard-page/unshipped-priority-cartons',
      queryOverviewMetrics: 'https://zzbzghnzl3.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-dashboard-service/dashboard-page/overview-metrics',
      queryToteTouchMetrics: 'https://zzbzghnzl3.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-dashboard-service/dashboard-page/tote-touch-metrics',
      queryHourlyOutboundMetrics: 'https://zzbzghnzl3.execute-api.us-east-1.amazonaws.com/prod/v2/dc-automations-dashboard-service/dashboard-page/hourly-outbound-metrics',
      queryAgedTotes: 'https://zzbzghnzl3.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-dashboard-service/aged-totes',
      queryRnRMetrics: 'https://zzbzghnzl3.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-dashboard-service/dashboard-page/rnr-metrics',
      queryChannelVolumeMetrics: 'https://zzbzghnzl3.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-dashboard-service/dashboard-page/channel-volume-metrics',
      queryAgedTotesDetails: 'https://zzbzghnzl3.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-dashboard-service/tote-detail-page/totes',
      queryRnRFailures: 'https://zzbzghnzl3.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-dashboard-service/rnr-metrics-detail-page/failures',
      queryCanceledTotesOverview: 'https://zzbzghnzl3.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-dashboard-service/dashboard-page/cancelled-totes-overview',
      queryCanceledTotesDetails: 'https://zzbzghnzl3.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-dashboard-service/cancelled-totes-detail-page/cancelled-totes',
      queryUnfulfilledCartonsSummary: 'https://zzbzghnzl3.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-dashboard-service/dashboard-page/unfulfilled-cartons-summary'
    },
    timeUsage: {
      queryTimeUsageSummary: 'https://o1gbqvg8ii.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-lm/time-usage-summary',
      queryTimeUsageTotals: 'https://o1gbqvg8ii.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-lm/time-usage-totals'
    },
    wmsOperations: {
      nonPackedCartons: {
        queryNonPackedCartons: 'https://2dlds1zpw0.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-http/query-non-packed-cartons'
      },
      cartonAuditAssignments: {
        queryCartonAuditAssignments: 'https://2dlds1zpw0.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-http/get-carton-audit-assignments',
        updateCartonAuditAssignment: 'https://2dlds1zpw0.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-http/update-carton-audit-assignment'
      },
      manageHardwareMapping: {
        queryHardwareMapping: 'https://2dlds1zpw0.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-http/query-hardware-mapping',
        createHardwareMapping: 'https://2dlds1zpw0.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-http/create-hardware-mapping',
        updateHardwareMapping: 'https://2dlds1zpw0.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-http/update-hardware-mapping',
        printerBarcodePrintRequest: 'https://2dlds1zpw0.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-http/printer-barcode-print-request'
      },
      waveOrders: {
        queryWaveOrders: 'https://2dlds1zpw0.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-http/query-wave-orders',
        createWaveOrder: 'https://0l1evz9r25.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-waving/wave-request',
        updateWaveOrder: 'https://2dlds1zpw0.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-http/update-wave-order',
        deleteWaveOrder: 'https://2dlds1zpw0.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-http/delete-wave-order'
      }
    },
    timesheet: {
      updateUserTimesheet: 'https://o1gbqvg8ii.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-lm/update-user-timesheet',
      queryMetricList: 'https://o1gbqvg8ii.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-lm/query-metric-list',
      queryAssociatesTimeUsage: 'https://o1gbqvg8ii.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-lm/associates-time-usages',
      queryLMUserIdList: 'https://o1gbqvg8ii.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-lm/query-lm-user-id-list',
      deleteUserTimesheetRecord: 'https://o1gbqvg8ii.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-lm/delete-user-timesheet-record',
      createUserTimesheetRecord: 'https://o1gbqvg8ii.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-lm/create-user-timesheet-record',
      queryUserTimesheet: 'https://o1gbqvg8ii.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-lm/query-user-timesheet'
    },
    unwavedDetail: {
      queryUnwavedDetail: 'https://2dlds1zpw0.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-http/query-unwaved-detail'
    },
    waveProcessingStatus: {
      queryWaveProcessingStatus: 'https://0l1evz9r25.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-waving/query-wave-processing-status'
    },
    autoWaveConfiguration: {
      queryAutoWaveConfigurations: 'https://0l1evz9r25.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-waving/auto-wave-configuration',
      updateAutoWaveConfigurations: 'https://0l1evz9r25.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-waving/auto-wave-configuration'
    },
    wavedUnits: {
      queryWavedSkus: 'https://0l1evz9r25.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-waving/waved-skus'
    },
    taskWave: {
      all: 'https://34xz5plp28.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-tasking/query-all-tasks',
      taskWave: 'https://34xz5plp28.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-tasking/task-wave',
      getSinglesBoxContainersIds: 'https://34xz5plp28.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-tasking/get-singles-box-containers-ids',
      getStrategyIdList: 'https://34xz5plp28.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-tasking/get-strategy-id-list',
      updateTaskingWave: 'https://34xz5plp28.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway-tasking/update-tasking-wave'
    },
    waveScheduleTemplate:{
      all: 'https://eikitro5gb.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway/schedule-template',
      get: 'https://eikitro5gb.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway/schedule-template',
      store: 'https://eikitro5gb.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway/schedule-template',
      update: 'https://eikitro5gb.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway/schedule-template',
      delete: 'https://eikitro5gb.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway/schedule-template'
    },
    waveScheduleSlot:{
      batchDelete: 'https://eikitro5gb.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway/batch-delete',
      batchUpdate: 'https://eikitro5gb.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway/batch-update',
      getDay: 'https://eikitro5gb.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway/schedule',
      storeDay: 'https://eikitro5gb.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway/schedule',
      get: 'https://eikitro5gb.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway/schedule-slot',
      store: 'https://eikitro5gb.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway/schedule-slot',
      update: 'https://eikitro5gb.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway/schedule-slot',
      delete: 'https://eikitro5gb.execute-api.us-east-1.amazonaws.com/prod/v1/dc-automations-gateway/schedule-slot'
    },
    shipmentWaveTemplate:{
      all:'https://xp9rdysg5d.execute-api.us-east-1.amazonaws.com/prod/v1/ship-wave/template',
      get:'https://xp9rdysg5d.execute-api.us-east-1.amazonaws.com/prod/v1/ship-wave/template',
      store: 'https://xp9rdysg5d.execute-api.us-east-1.amazonaws.com/prod/v1/ship-wave/template',
      delete: 'https://xp9rdysg5d.execute-api.us-east-1.amazonaws.com/prod/v1/ship-wave/template'
    },
    piiRequest:{
      all: 'https://wiirafnqo1.execute-api.us-east-1.amazonaws.com/prod/v1/pii-request/requests',
      get:  'https://wiirafnqo1.execute-api.us-east-1.amazonaws.com/prod/v1/pii-request/requests',
      store: 'https://wiirafnqo1.execute-api.us-east-1.amazonaws.com/prod/v1/pii-request/requests',
      delete: 'https://wiirafnqo1.execute-api.us-east-1.amazonaws.com/prod/v1/pii-request/requests'
    },
    shippingThresholdSchedule:{
      all: 'https://7kc4c093nc.execute-api.us-east-1.amazonaws.com/prod/v1/shipping-threshold/schedules',
      get:  'https://7kc4c093nc.execute-api.us-east-1.amazonaws.com/prod/v1/shipping-threshold/schedules',
      store: 'https://7kc4c093nc.execute-api.us-east-1.amazonaws.com/prod/v1/shipping-threshold/schedules',
      update: 'https://7kc4c093nc.execute-api.us-east-1.amazonaws.com/prod/v1/shipping-threshold/schedules',
      delete: 'https://7kc4c093nc.execute-api.us-east-1.amazonaws.com/prod/v1/shipping-threshold/schedules',
      overwrite: 'https://7kc4c093nc.execute-api.us-east-1.amazonaws.com/prod/v1/shipping-threshold/overwrite',
      batchUpdate: 'https://7kc4c093nc.execute-api.us-east-1.amazonaws.com/prod/v1/shipping-threshold/schedules-batch-update',
      batchDelete: 'https://7kc4c093nc.execute-api.us-east-1.amazonaws.com/prod/v1/shipping-threshold/schedules-batch-delete'
    },
    shippingThresholdOption:{
      get:  'https://7kc4c093nc.execute-api.us-east-1.amazonaws.com/prod/v1/shipping-threshold/schedules/options',
      update: 'https://7kc4c093nc.execute-api.us-east-1.amazonaws.com/prod/v1/shipping-threshold/schedules/options'
    }
  },
  shopifyMarkets: {
    getMarket: 'https://rgkt2yxdah.execute-api.us-east-1.amazonaws.com/prod/v1/excelsior-shopify-service/get-market',
    getMarkets: 'https://rgkt2yxdah.execute-api.us-east-1.amazonaws.com/prod/v1/excelsior-shopify-service/get-markets'
  },
  marketingStrategy: {
    variants: 'https://hlxqta7qj5.execute-api.us-east-1.amazonaws.com/v1/variants/'
  }
};
